import React, { Fragment } from 'react';
import styled from 'styled-components';
import Surface from './surface';
import { MESSAGES } from '../constants';

const SurfacesContainer = styled.div`
  margin: 25px 17px;
`;

const Button = styled.button`
  margin: 8px auto;
  display: block;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;

  :before {
    content: '+'
  }
`;

const SurfacesHolder = ({ 
    surfaces,
    addSurface,
    removeSurface,
    updateSurface,
    rollParams 
}) => {

    const cleanValue = value => Math.round(value.replace(/\D+/g, ''));

    const setLength = (key, value) => {
        const length = cleanValue(value);
        const { width } = surfaces[key];
        updateSurface({ key, width, length });
    };

    const setWidth = (key, value) => {
        const width = cleanValue(value);
        const { length } = surfaces[key];
        updateSurface({ key, width, length });
    };

    const entries = Object.entries(surfaces);

    return (
        <SurfacesContainer>
            {entries.map(entry => {
                const [key, value] = entry;

                return (
                    <Fragment key={key}>
                        {
                            value && <Surface
                                surfaceKey={key}
                                surfaceValue={value}
                                rollParams={rollParams}
                                removeSurface={removeSurface}
                                setLength={setLength}
                                setWidth={setWidth}
                            />
                        }
                    </Fragment>
                );
            })}
            <Button onClick={addSurface}>{MESSAGES.ADD_WALL}</Button>
        </SurfacesContainer>);
};

export default SurfacesHolder;
