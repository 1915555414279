import styled from 'styled-components';
import { MESSAGES } from '../constants';
import Input from './input';
import Calculations from './calculations';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px
`;

const Column = styled.div`
  display: flex;
  justify-content: ${({ center }) => center ? 'center' : 'space-between'};
  align-items: center;
  ${({ width }) => width ? 'width: ' + width + 'px;' : ''}
  color: #ccc;
  font-size: 14px;
  font-family: Arial,sans-serif;
`;

const RemoveButton = styled.div`
  border-radius: 20px;
  border: none;
  box-shadow: 0 2px 5px #faa;
  background-color: #fff;
  color: #e00;
  font-weight: 700;
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  font-family: Arial, sans-serif;
  text-transform: lowercase;
`;

const Surface = ({
    surfaceKey,
    surfaceValue: {
     length,
     width,
     calculations
    },
    removeSurface,
    setLength,
    setWidth
}) => {
    const handleRemoveSurface = () => removeSurface(surfaceKey);
    const handleLengthChange = e => setLength(surfaceKey, e.target.value);
    const handleWidthChange = e => setWidth(surfaceKey, e.target.value);

    return (
        <Container>
            <Column center={true}>
                <RemoveButton onClick={handleRemoveSurface}>x</RemoveButton>
            </Column>
            <Column width={230}>
                <Input
                    placeholder={MESSAGES.LENGTH}
                    onChange={handleLengthChange}
                    value={length}
                />
                X
                <Input
                    placeholder={MESSAGES.WIDTH}
                    value={width}
                    onChange={handleWidthChange}
                />
            </Column>
            <Column center={true}>
                <Calculations calculations={calculations} />
            </Column>
        </Container>
    );
};

export default Surface;