import React from 'react';
import styled from 'styled-components';
import { Tooltip as BaseTooltip } from 'react-tippy';
import { MESSAGES } from '../constants';
import InfoIcon from './info_icon';
import RollInfo from './roll_info';

const Tooltip = styled(BaseTooltip)`
  margin-right: -108px;
  position: absolute;
`;

const HeaderWrapper = styled.div`
  color: #eee;
  background-color: #000;
  padding: 18px;
  text-align: center;
  direction: rtl;
`;

const Header = ({ rollParams }) => (
    <HeaderWrapper>
        <Tooltip
            html={<RollInfo rollParams={rollParams} />}
            position="bottom"
            trigger={'mouseenter focus click'}
            arrow={true}
            theme={'light'}
        >
            <InfoIcon color={'#fff'} stroke={true}/>
        </Tooltip>
        {MESSAGES.TITLE}
    </HeaderWrapper>
);

export default Header;
