import { Fragment } from 'react';
import styled from 'styled-components';
import { MESSAGES } from '../constants';

const Detail = styled.div`
  font-size: 14px;
  color: #aaa;
  direction: rtl;
  margin: 5px;
`;

const WallInfo = ({
    calculations: { surfaceSizeInM, rolls }
}) => (
    surfaceSizeInM
        ? <Fragment>
            <Detail>{MESSAGES.WALL_SIZE} {surfaceSizeInM} {MESSAGES.SQUARE_METERS}</Detail>
            <Detail>{MESSAGES.ROLLS} {rolls}</Detail>
          </Fragment>
        : MESSAGES.ADD_WALL_SIZE
);

export default WallInfo;
