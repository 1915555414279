import { MESSAGES } from '../constants';
import React from 'react';
import styled from 'styled-components';

const ResultsContainer = styled.div`
  margin: 16px auto;
  padding: 8px;
  border: 1px solid #333;
  width: 200px;
  font-size: 14px;
  text-align: center;
`;

const Results = ({ totalSize, totalRolls }) => (
    <ResultsContainer>
        {MESSAGES.ROLLS} {totalRolls} / {totalSize} {MESSAGES.SQUARE_METERS}
    </ResultsContainer>
);

export default Results;