import { Fragment } from 'react';
import { Tooltip } from 'react-tippy';
import '../assets/tippy.css';
import InfoIcon from './info_icon';
import WallInfo from './wall_info';

const Calculations = ({ calculations }) => (
    <Fragment>
        <Tooltip
            html={<WallInfo calculations={calculations} />}
            position="bottom"
            trigger={'mouseenter focus click'}
            arrow={true}
            theme={'light'}
        >
            <InfoIcon color={calculations.surfaceSizeInM ? '#00BFFF' : '#999'}/>
        </Tooltip>
    </Fragment>
);

export default Calculations;
