import React from 'react';
import styled from 'styled-components';
import { MESSAGES } from './constants';
import Header from './components/header';
import SurfacesHolder from './components/surfaces_holder';
import Results from './components/results';
import { useSurfaceCalc } from './hooks/use_surface_calc';


const Container = styled.div`
  margin: 15px auto;
  max-width: 330px;
  border: 1px solid #ddd;
  padding-bottom: 25px;
  font-family: Arial, sans-serif;
`;

const Info = styled.div`
  margin-top: 15px;
  font-size: 13px;
  text-align: center;
  color: #999;
`;

const App = () => {
    const {
        surfaces,
        addSurface,
        removeSurface,
        updateSurface,
        rollParams,
        totalSize,
        totalRolls
    } = useSurfaceCalc();

    return (
        <Container>
            <Header rollParams={rollParams}/>
            <SurfacesHolder
                surfaces={surfaces}
                addSurface={addSurface}
                removeSurface={removeSurface}
                updateSurface={updateSurface}
                rollParams={rollParams}
            />
            <Results totalSize={totalSize} totalRolls={totalRolls} />
            <Info>{MESSAGES.INFO_WARNING}</Info>
        </Container>
    );
};

export default App;
