import { Fragment } from 'react';
import styled from 'styled-components';
import { MESSAGES } from '../constants';

const Detail = styled.div`
  font-size: 14px;
  color: #aaa;
  direction: rtl;
  margin: 5px;
`;

const RollInfo = ({
    rollParams: { rollWidth, cylinderLength, periodicity }
}) => {
    return (
        <Fragment>
            <Detail>{MESSAGES.CALCULATION_BASED_ON}</Detail>
            <Detail>{MESSAGES.ROLL_WIDTH} {rollWidth} {MESSAGES.CENTIMETERS}</Detail>
            <Detail>{MESSAGES.ROLL_LENGTH} {cylinderLength} {MESSAGES.CENTIMETERS}</Detail>
            <Detail>{MESSAGES.ROLL_PERIODICITY} {periodicity} {MESSAGES.CENTIMETERS}</Detail>
        </Fragment>
    );
};

export default RollInfo;
