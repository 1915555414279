import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
  box-shadow: 0 1px 4px ${({ color }) => color};
  border-radius: 18px;
  width: 18px;
  height: 18px;
  display: block;
  ${({ withStroke }) => withStroke ? 'border: 1px solid #999' : ''}
`;

const InfoIcon = ({ color= '#999', size = 18, stroke = false }) => (
    <IconContainer color={color} withStroke={stroke}>
        <svg width={size} height={size} version="1.1" viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">
            <circle cx={size/2} cy={size*.25} r="0.1" fill="none" stroke={color} strokeMiterlimit={size/2} strokeWidth={size/10}/>
            <line x1={size/2} y1={size*.45} x2={size/2} y2={size*.8} stroke={color} strokeMiterlimit={size/2} strokeWidth={size/10}/>
        </svg>
    </IconContainer>
);

export default InfoIcon;
