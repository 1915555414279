export const MESSAGES = {
    TITLE: 'מחשבון גלילים',
    WIDTH: 'רוחב',
    LENGTH: 'גובה',
    ROLLS: 'גלילי טפט:',
    ADD_WALL: 'הוסף קיר',
    ADD_WINDOW: 'הוסף חלון',
    ROLLS_AMOUNT: 'סכ”ה גלילים',
    INFO_WARNING: 'המחשבון גלילים לא מדוייק ב-100% אך מומלץ לשימוש',
    SQUARE_METERS: 'מ"ר',
    CENTIMETERS: 'ס"מ',
    CALCULATION_BASED_ON: 'החישוב מבוסס על:',
    ROLL_LENGTH: 'אורך הגליל:',
    ROLL_WIDTH: 'רוחב הגליל:',
    ROLL_PERIODICITY: 'מחזוריות:',
    WALL_SIZE: 'גודל הקיר:',
    ADD_WALL_SIZE: 'נא להזין את גודל הקיר'
};
