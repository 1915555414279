export const getSurfaceInMeters = surfaceSize => {
    if (surfaceSize) {
        const surfaceInMeters = surfaceSize / 10000;
        const fractionDigits = surfaceInMeters > 99.99 ? 0 : 1;
        return +surfaceInMeters.toFixed(fractionDigits);
    } else {
        return surfaceSize;
    }
};

export const calculateRolls = ({ wallWidth, wallHeight, rollParams: { rollWidth, cylinderLength, periodicity } }) => {
    const dropMatch = 1.5;
    const cyclesPerStrip = wallHeight / periodicity;

    const totalCyclesPerStrip = Math.floor(cyclesPerStrip) + dropMatch;
    const stripLength = totalCyclesPerStrip * periodicity;

    const stripsPerWall = Math.ceil(wallWidth / rollWidth );
    const stripsPerRoll = Math.floor(cylinderLength / stripLength);
    return stripsPerRoll ? Math.ceil(stripsPerWall / stripsPerRoll) : 0;
};
