import { useEffect, useState } from 'react';
import { calculateRolls, getSurfaceInMeters } from '../utils/converter_utils';

export const useSurfaceCalc = () => {
    const [totalSize, setTotalSize] = useState(0);
    const [totalRolls, setTotalRolls] = useState(0);
    const [surfaces, setSurfaces] = useState({ 0: { length: '', width: '', calculations: {} } });
    const [lastKey, setLastKey] = useState(1);

    useEffect(() => {
        const surfaceCalculations = Object.entries(surfaces)
            .map(surface => surface[1]?.calculations);

        const surfaceSize = surfaceCalculations.map(surface => surface?.surfaceSize)
            .filter(surface => surface)
            .reduce((a, b) => a + b, 0);
        setTotalSize(getSurfaceInMeters(surfaceSize));

        const rolls = surfaceCalculations.map(surface => surface?.rolls)
            .filter(surface => surface)
            .reduce((a, b) => a + b, 0);
        setTotalRolls(rolls);
    }, [surfaces]);

    const dimensionsContainer = document.getElementsByClassName('woocommerce-product-attributes-item--dimensions')[0];
    const dimensions = dimensionsContainer?.getElementsByClassName('woocommerce-product-attributes-item__value')[0].innerHTML;
    const values = dimensions?.split('×');
    const rollWidth = (values[0]?.replace(/[^0-9.]/g, '')*100).toFixed(2);
    const cylinderLength = (values[1]?.replace(/[^0-9.]/g, '')*100).toFixed(0);

    const repeatContainer = document.getElementsByClassName('woocommerce-product-attributes-item--attribute_repeat')[0];
    const periodicityValue = repeatContainer?.getElementsByClassName('woocommerce-product-attributes-item__value')[0].innerHTML;
    const periodicity = periodicityValue?.replace(/[^0-9.]/g, '');

    const rollParams = {
        rollWidth,
        cylinderLength,
        periodicity
    };

    const addSurface = () => {
        setSurfaces({ ...surfaces, [lastKey]: { length: '', width: '', calculations: {} } });
        setLastKey(lastKey + 1);
    };

    const removeSurface = key => {
        setSurfaces({ ...surfaces, [key]: undefined });
    };

    const updateSurface = ({ key, width, length }) => {
        const calculations = width && length ? {
            surfaceSize: width * length,
            surfaceSizeInM: getSurfaceInMeters(width * length),
            rolls: calculateRolls({
                wallWidth: width,
                wallHeight: length,
                rollParams
            }),
        } : {};

        setSurfaces({
            ...surfaces,
            [key]: { width, length, calculations }
        });
    };

    return {
        surfaces,
        addSurface,
        removeSurface,
        updateSurface,
        rollParams,
        totalSize,
        totalRolls
    };
};
