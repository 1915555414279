import styled from 'styled-components';

const InputContainer = styled.div`
    :before {
        display: ${({ value }) => value !== '' ? 'block' : 'none'};
        position: absolute;
        margin-left: 10px;
        margin-top: 15px;
        z-index: 100;
        content: 'ס"מ';
        font-size: 13px;
        color: #aaa;
    }
`;

const Input = styled.input`
    width: 100px;
    text-align: ${({ value }) => value !== '' ? 'right' : 'center'};
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 0
`;


const InputElement = ({ placeholder, value, onChange }) => (
    <InputContainer value={value} >
        <Input placeholder={placeholder} value={value} onChange={onChange} type={'number'}/>
    </InputContainer>);

export default InputElement;